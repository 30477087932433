import React from 'react'
import Header from './header'
import '../styles/layout.css'
import * as styles from './layout.module.css'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FacebookIcon from '@mui/icons-material/Facebook';

const navList = [
  {
    title: 'Κάγκελα Ανοδίωσης',
    url: '/anodized-rails'
  },
  {
    title: 'Πόρτες / Περιφράξεις Ανοδίωσης',
    url: '/anodized-door-enclosures'
  },
  {
    title: 'Κάγκελα Inox',
    url: '/inox-rails'
  },
  {
    title: 'Περιφράξεις Inox',
    url: '/inox-enclosures'
  },
  // {
  //   title: 'Ειδικές Κατασκευές Inox',
  //   url: '/inox-special-constructions'
  // },
  {
    title: 'Στέγαστρα',
    url: '/shelters'
  },
  {
    title: 'Πέργκολες',
    url: '/pergoles'
  },
  // {
  //   title: 'Διακόσμηση Καταστημάτων',
  //   url: '/shop-decorations'
  // }
]

const Layout = ({children, siteTitle, logo, address, telephone, mobile, email, fblink}) => (

  <>
    <Header siteTitle={siteTitle} logo={logo} />
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
      <Grid container spacing={3} style={{borderBottom: '3px solid #fff', width: '90%', margin: '0 auto'}}>
        <Grid item md={6} style={{padding: 30, textAlign: 'center', margin: '0 auto'}}>
          <Typography variant="h6">Σύνδεσμοι</Typography>
            <hr style={{width: '3%'}}/>
            {navList.map(navItem => (
              <Typography variant="h7" style={{textAlign: 'center'}}>
                <a href={navItem.url} style={{display: 'block'}}>{navItem.title}</a>
              </Typography>
            ))}
        </Grid>
        <Grid item md={6} style={{padding: 30, textAlign: 'center', margin: '0 auto'}}>
          <Typography variant="h6">Επικοινωνία</Typography>
          <hr style={{width: '3%'}}/>
          <Typography variant="h7">{address}</Typography><br/>
          <Typography variant="h7">Υπεύθυνος: Ιωάννης Πάνου</Typography><br/>
          <Button style={{marginLeft: 'auto', textTransform: 'lowercase'}} color="inherit"><PhoneIcon style={{marginRight: 5}}/>: <Typography variant="h7">{telephone}</Typography></Button>
          <Button style={{marginLeft: 'auto', textTransform: 'lowercase'}} color="inherit"><PhoneAndroidIcon style={{marginRight: 5}}/>: <Typography variant="h7">{mobile}</Typography></Button><br/>
          <Button style={{marginLeft: 'auto', textTransform: 'lowercase'}} color="inherit"><AlternateEmailIcon style={{marginRight: 5}}/>: <Typography variant="h7">{email}</Typography></Button><br/>
          <Button onClick={() => window.open(fblink)} style={{marginLeft: 'auto', textTransform: 'lowercase'}} color="inherit"><FacebookIcon style={{marginRight: 5}}/></Button>
        </Grid>
      </Grid>
      <div className={styles.footerWrapper}>
        <div className={styles.siteInfo}>
          <h7>© {new Date().getFullYear()} JPInox</h7><br/>
          <span>Powered by Petros M. Patelis</span>
        </div>
      </div>
    </footer>
  </>
)

export default Layout
